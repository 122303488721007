import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { Fab } from '@material-ui/core';

import _ from 'underscore';

import AddIcon from '@material-ui/icons/Add';

import MainScreenBar from '../navigation/MainScreenBar'
import Rezepte from '../rest/Rezepte';
import history from "../navigation/History";
import EditRezeptDialog from './EditRezeptDialog';
import EditKochbuchDialog from '../kochbuecher/EditKochbuchDialog';
import DeleteKochbuchDialog from './DeleteKochbuchDialog';
import Kategorien from "../rest/Kategorien";
import Kochbuecher from "../rest/Kochbuecher";

import Footer from '../navigation/Footer';
import HeroContent from '../navigation/HeroContent'
import GridContainer from '../navigation/GridContainer'
import GridEntry from '../navigation/GridEntry'
import DeleteRezeptDialog from "./DeleteRezeptDialog";


const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
  },
}));


export default function RezepteScreen(props) {
  const classes = useStyles();
  const {toggleDrawer} = props;
  const {name, kategorieId, kochbuchId} = props.match.params;
  
  const [searchString, setSearchString] = useState('');
  const [rezepte, setRezepte] = useState([]);
  const [kochbuch, setKochbuch] = useState({});
  const [showEditKochbuchDialog, setShowEditKochbuchDialog] = useState(false);
  const [showDeleteKochbuchDialog, setShowDeleteKochbuchDialog] = useState(false);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedRezept, setSelectedRezept] = useState(undefined);
  
  const [title, setTitle] = useState(name!==undefined ? name : 'Rezepte');
  const [kategorien, setKategorien] = useState([]);
  const [kochbuecher, setKochbuecher] = useState([]);
  
  let editRezept = {};
  let editKochbuch = {};

  async function loadRezepte() {
    let rezepte;
    
    // Rezepte laden
    if (kategorieId!==undefined) {
      rezepte = await Rezepte.readFromKategorie(kategorieId);
    } else if (kochbuchId!==undefined) {
      rezepte = await Rezepte.readFromKochbuch(kochbuchId);
    } else if (searchString!==undefined && searchString!=='') {
      rezepte = await Rezepte.search(searchString);
    } else {
      rezepte = await Rezepte.read();
    }
    
    // ... und um Kategorie erweitern
    let kochbuecher = await Kochbuecher.read();
    let kategorien = await Kategorien.read();
    let kategorieMap = [];
    kategorien.forEach(function(kategorie) {
      kategorieMap[kategorie.kategorieId] = kategorie;
    });
    let kochbuch = {};
    kochbuecher.forEach(function(kochbuchVal) {
      if (kochbuchVal.kochbuchId===kochbuchId) {
        kochbuch = kochbuchVal;
      }
    });
    
    rezepte.forEach(function(rezept) {
      if (rezept.kategorieId!==undefined && kategorieMap[rezept.kategorieId]!==undefined) {
        rezept.kategorieName = kategorieMap[rezept.kategorieId].name;
      } else {
        rezept.kategorieName = '';
      }
    });
    
    setRezepte(rezepte);
    setKategorien(kategorien);
    setKochbuecher(kochbuecher);
    setKochbuch(kochbuch);
  }

  
  useEffect(() => {
    loadRezepte();
  }, []);


  function showAddRezeptDialog(rezept) {
    setSelectedRezept(rezept)
    editRezept = {
      name: undefined,
      kategorieId: kategorieId,
      seite: 1,
      kochbuchId: kochbuchId,
    };
    setShowCreateDialog(true);
  }


  function _showEditKochbuchDialog() {
    editKochbuch = kochbuch;
    setShowEditKochbuchDialog(true);
  }


  async function handleCreateEditRezept(rezept) {
    setShowCreateDialog(false);
    if (!_.isUndefined(rezept)) {
      if (selectedRezept) {
        await Rezepte.update(rezept);
      } else {
        await Rezepte.create(rezept);
      }
      await loadRezepte();
    }
  }
  
  async function handleDeleteRezept(confirmDelete) {
    setShowDeleteDialog(false);
    if (confirmDelete) {
      await Rezepte.delete(selectedRezept);
      await loadRezepte();
    }
  }
  
  function handleSearch(searchString) {
    setSearchString(searchString);
    loadRezepte();
  }


  function handleMenu(key) {
    if (key==='UB') {
      _showEditKochbuchDialog();
    } else if (key==='DB') {
      setShowDeleteKochbuchDialog(true);
    } else if (key==='CR') {
      showAddRezeptDialog();
    }
  }


  async function handleEditKochbuch(kochbuch) {
    setShowEditKochbuchDialog(false);
    if (!_.isUndefined(kochbuch)) {
      await Kochbuecher.update(kochbuch);
      setTitle(kochbuch.name);
      loadRezepte();
    }
  }


  async function handleDeleteKochbuch(confirmDelete) {
    setShowDeleteKochbuchDialog(false);
    if (confirmDelete) {
      await Kochbuecher.delete(this.state.kochbuch);
      history.goBack();
    }
  }

  let menuItems = [{name: 'Neues Rezept', key: 'CR'},];
  if (kochbuchId!==undefined) {
    menuItems.push(
      {name: 'Kochbuch bearbeiten', key: 'UB'},
      {name: 'Kochbuch löschen', key: 'DB'},
    );
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <MainScreenBar
        toggleDrawer={toggleDrawer}
        title={title}
        mainScreen={title==="Rezepte"}
        onSearch={kategorieId===undefined && kochbuchId===undefined ? handleSearch : undefined}
        menuItems={menuItems}
        onMenuSelected={handleMenu}
      />
      <main>
        {/* Hero unit */}
        <HeroContent
          title={title}
        />
  
        {/* Recipes */}
        <GridContainer>
          {rezepte.map((rezept) => (
            <GridEntry
              key={rezept.rezeptId}
              title={rezept.name}
              thumbnail={Rezepte.getThumbnail(rezept.rezeptId)}
              viewLink={'/#/rezept/'+rezept.rezeptId}
              editLink={() => showAddRezeptDialog(rezept)}
              deleteLink={() => {
                setSelectedRezept(rezept);
                setShowDeleteDialog(true);
              }}
              sizeX={12}
              sizeL={4}
            />
          ))}
        </GridContainer>
      </main>
  
      {/* Footer */}
      <Footer />

      <Fab color="primary" aria-label="Add" className={classes.fab} onClick={() => showAddRezeptDialog()}>
        <AddIcon/>
      </Fab>

      <EditKochbuchDialog open={showEditKochbuchDialog} kochbuch={editKochbuch} onClose={handleEditKochbuch}/>
      <DeleteKochbuchDialog open={showDeleteKochbuchDialog} kochbuch={kochbuch} onClose={handleDeleteKochbuch}/>
      <EditRezeptDialog open={showCreateDialog} rezept={selectedRezept} kategorien={kategorien} kochbuecher={kochbuecher} onClose={handleCreateEditRezept}/>
      <DeleteRezeptDialog open={showDeleteDialog} rezept={selectedRezept} onClose={handleDeleteRezept}/>

    </React.Fragment>
  );
}
