import React, { useState, useEffect } from 'react';
import AppFrame from './AppFrame';
import './App.css';
import SignIn from "./settings/SignIn";
import Rest from "./rest/Rest";
import {Buffer} from "buffer";


export default function App() {
  const [init, setInit] = useState(true);
  const [signIn, setSignIn] = useState(localStorage.getItem('auth')===null);
  
  function onLogin(user, password) {
    localStorage.setItem('auth', new Buffer(user+':'+password).toString('base64'));
    localStorage.setItem('user', user);
    doLogin();
  }


  async function doLogin() {
    let success = await Rest.login();
    if (success) {
      setSignIn(false);
    } else {
      localStorage.removeItem('user');
      localStorage.removeItem('auth');
      setSignIn(true);
    }
  }

  
  useEffect(() => {
    async function handleLogin() {
      Rest.init(window.location);
      await doLogin();
      setInit(false);
    }
    handleLogin();
  }, []);
  

  if (!init) {
    if (signIn) {
      return <SignIn
        onLogin={onLogin}
      />;
    } else {
      return (<AppFrame/>);
    }
  } else {
    return null;
  }
}
