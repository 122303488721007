import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import {Link, Typography} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1, 0, 2),
  },
}));


export default function Footer() {
  const classes = useStyles();
  
  return (
    <footer className={classes.footer}>
      <Typography variant="h6" align="center" gutterBottom>
        Rezepte
      </Typography>
      <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
        Gesammelt von Babsi und Manfred
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://material-ui.com/">
          rezepte.miessl.net
        </Link>{' '}
        {new Date().getFullYear()}
      </Typography>
    </footer>
  );
}