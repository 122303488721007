import Rest from "./Rest";

export default class Rezepte extends Rest {
  static read = function(rezeptId) {
    let restUrl = Rest.config.server + '/api/rezepte' + (rezeptId!==undefined ? '/'+rezeptId : '');
    return fetch(restUrl, Rest.createHeader()).then(function(response) {
      return response.json();
    });
  };


  static readFromKategorie = function(kategorieId) {
    let restUrl = Rest.config.server + '/api/kategorie/' + kategorieId;
    return fetch(restUrl, Rest.createHeader()).then(function(response) {
      return response.json();
    });
  };


  static readFromKochbuch = function(kochbuchId) {
    let restUrl = Rest.config.server + '/api/kochbuch/' + kochbuchId;
    return fetch(restUrl, Rest.createHeader()).then(function(response) {
      return response.json();
    });
  };


  static search = function(query) {
    let restUrl = Rest.config.server + '/api/rezeptquery/' + query;
    return fetch(restUrl, Rest.createHeader()).then(function(response) {
      return response.json();
    });
  };


  static create = function(rezept) {
    let restUrl = Rest.config.server + '/api/rezepte';

    let formData = new FormData();
    formData.append('name', rezept.name);
    formData.append('seite', rezept.seite);
    formData.append("kategorieId", rezept.kategorieId);
    formData.append("kochbuchId", rezept.kochbuchId);
    formData.append("file", rezept.file);
    if (rezept.thumbnail!==undefined) {
      formData.append("thumbnail", rezept.thumbnail);
    }
  
    return fetch(restUrl, Rest.createHeader('put', formData)).then(function(response) {
      return response.json();
    });
  };


  static update = function(rezept) {
    let restUrl = Rest.config.server + '/api/rezepte/' + rezept.rezeptId;
    let formData = new FormData();
    formData.append('name', rezept.name);
    formData.append('seite', rezept.seite);
    formData.append("kategorieId", rezept.kategorieId);
    formData.append("kochbuchId", rezept.kochbuchId);
    if (rezept.file!==undefined) {
      formData.append("file", rezept.file);
    }
    if (rezept.thumbnail!==undefined) {
      formData.append("thumbnail", rezept.thumbnail);
    }
    return fetch(restUrl, Rest.createHeader('post', formData)).then(function(response) {
      return response.json();
    });
  };


  static delete = function(rezept) {
    let restUrl = Rest.config.server + '/api/rezepte/' + rezept.rezeptId;
    return fetch(restUrl, Rest.createHeader('delete')).then(function(response) {
      return response.json();
    });
  };


  static addToKategorie = function(rezeptId, kategorieId) {
    let restUrl = Rest.config.server + '/api/kategorie/' + kategorieId + '/' + rezeptId;
    return fetch(restUrl, Rest.createHeader('put')).then(function(response) {
      return response.json();
    });
  };


  static removeFromKategorie = function(rezeptId, kategorieId) {
    let restUrl = Rest.config.server + '/api/kategorie/' + kategorieId + '/' + rezeptId;
    return fetch(restUrl, Rest.createHeader('delete')).then(function(response) {
      return response.json();
    });
  };


  static getThumbnail = function(rezeptId) {
    return `${Rest.config.server}/api/file/thumb_${rezeptId}?rid=${localStorage.getItem('auth')}`;
  };
  
}
