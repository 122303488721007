import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button} from '@material-ui/core';
import {TextField} from '@material-ui/core';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';


function EditKategorieDialog(props) {
  const {kategorie, open, onClose} = props;
  const [editName, setEditName] = useState();
  const [editThumbnail, setEditThumbnail] = useState();
  const [editThumbnailName, setEditThumbnailName] = useState();
  const edit = kategorie!==undefined;
  
  useEffect(() => {
    setEditName(kategorie ? kategorie.name : undefined);
    setEditThumbnail(kategorie ? kategorie.thumbnail : undefined);
    setEditThumbnailName(kategorie ? kategorie.thumbnailName : undefined);
  }, [kategorie]);
  
  // Handle Save-button
  function onSave() {
    onClose({
      kategorieId: kategorie ? kategorie.kategorieId : undefined,
      name: editName,
      thumbnail: editThumbnail,
      thumbnailName: editThumbnailName,
    });
  }
  
  // Handle Cancel-button
  function onCancel() {
    onClose();
  }
  
  return <Dialog
    open={open}
    aria-labelledby="form-dialog-title"
  >
    <DialogTitle id="form-dialog-title">{`Kategorie ${edit?'bearbeiten':'anlegen'}`}</DialogTitle>
    <DialogContent>
      <TextField
        autoFocus
        margin="dense"
        id="name"
        label="Name"
        type="text"
        fullWidth
        value={editName}
        onChange={(e) => setEditName(e.target.value)}
      />
      <TextField
        id="thumbnail" label="Thumbnail"
        value={editThumbnailName}
        onChange={e => {
          setEditThumbnailName(e.target.value);
          setEditThumbnail(e.target.files[0]);
        }}
        type="file"
        fullWidth
        margin="normal"
        InputLabelProps={{ shrink: true }}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} color="primary">
        Abbrechen
      </Button>
      <Button onClick={onSave} color="primary">
        {edit ? 'Speichern' : 'Anlegen'}
      </Button>
    </DialogActions>
  </Dialog>;
}

EditKategorieDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  kategorie: PropTypes.object,
};

export default EditKategorieDialog;

