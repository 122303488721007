import React, { Component } from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import './App.css';
import Sidebar from './navigation/Sidebar';
import KategorienScreen from './kategorien/KategorienScreen';
import RezepteScreen from './rezepte/RezepteScreen';
import RezeptScreen from './rezepte/RezeptScreen';
import ErinnerungenScreen from './erinnerungen/ErinnerungenScreen';
import KochbuecherScreen from './kochbuecher/KochbuecherScreen';


class AppFrame extends Component {
  state = {
    drawerOpen: false,
  };

  
  constructor(props) {
    super(props);
    this.toggleDrawer = this.toggleDrawer.bind(this);
  }


  toggleDrawer(open) {
    this.setState({drawerOpen: open,});
  }
  
  
  render() {
    return (
      <div>
        <Sidebar
          open={this.state.drawerOpen}
          toggleDrawer={this.toggleDrawer}
        />

        <Switch>
          <Route exact path='/' render={props => (
            <KategorienScreen {...props} toggleDrawer={this.toggleDrawer}/>
          )}/>
          <Route exact path='/kategorien' render={props => (
            <KategorienScreen {...props} toggleDrawer={this.toggleDrawer}/>
          )}/>

          <Route exact path='/rezepte' render={props => (
            <RezepteScreen {...props} toggleDrawer={this.toggleDrawer}/>
          )}/>
          <Route path='/kategorie/:kategorieId/:name' render={props => (
            <RezepteScreen {...props} toggleDrawer={this.toggleDrawer}/>
          )}/>
          <Route path='/kochbuch/:kochbuchId/:name' render={props => (
            <RezepteScreen {...props} toggleDrawer={this.toggleDrawer}/>
          )}/>
          <Route path='/rezept/:rezeptId' render={props => (
            <RezeptScreen {...props} toggleDrawer={this.toggleDrawer}/>
          )}/>

          <Route exact path='/erinnerungen' render={props => (
            <ErinnerungenScreen {...props} toggleDrawer={this.toggleDrawer}/>
          )}/>
          <Route exact path='/kochbuecher' render={props => (
            <KochbuecherScreen {...props} toggleDrawer={this.toggleDrawer}/>
          )}/>
          <Route exact path='/logout' render={() => localStorage.removeItem('user')}>
            <Redirect to="/" />
          </Route>
        </Switch>
      </div>
    );
  }
}

export default AppFrame;
