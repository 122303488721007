import Rest from "./Rest";

export default class Kategorien extends Rest {
  static read = function() {
    let restUrl = Rest.config.server + '/api/kategorie';
    return fetch(restUrl, Rest.createHeader()).then(function(response) {
      if (response.status===200) {
        return response.json();
      } else {
        throw new Error("Benutzer/Passwort nicht erkannt.");
      }
    });
  };


  static create = function(kategorie) {
    let restUrl = Rest.config.server + '/api/kategorie';
    let formData = new FormData();
    formData.append('name', kategorie.name);
    if (kategorie.thumbnail!==undefined) {
      formData.append("thumbnail", kategorie.thumbnail);
    }
    return fetch(restUrl, Rest.createHeader('put', formData)).then(function(response) {
      return response.json();
    });
  };


  static update = function(kategorie) {
    let restUrl = Rest.config.server + '/api/kategorie/' + kategorie.kategorieId;
    let formData = new FormData();
    formData.append('name', kategorie.name);
    if (kategorie.thumbnail!==undefined) {
      formData.append("thumbnail", kategorie.thumbnail);
    }
    return fetch(restUrl, Rest.createHeader('post', formData)).then(function(response) {
      return response.json();
    });
  };


  static delete = function(kategorieId) {
    let restUrl = Rest.config.server + '/api/kategorie/' + kategorieId;
    return fetch(restUrl, Rest.createHeader('delete')).then(function(response) {
      return response.json();
    });
  };


  static getThumbnail = function(kategorieId) {
    return `${Rest.config.server}/api/file/thumb_${kategorieId}?rid=${localStorage.getItem('auth')}`;
  };
}
