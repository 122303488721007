import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {fade} from '@material-ui/core/styles/colorManipulator';
import InputBase from '@material-ui/core/InputBase';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import {Menu, MenuItem} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import BackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreVert';

import history from './History';


class MainScreenBar extends Component {
  state = {
    anchorEl: null,
    showSearch: false,
    searchString: '',
  };

  constructor(props) {
    super(props);
    this.toggleDrawer = this.props.toggleDrawer;
  }

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = (key) => {
    const {onMenuSelected} = this.props;
    this.setState({ anchorEl: null });
    if (onMenuSelected!==undefined && key!==undefined) {
      onMenuSelected(key);
    }
  };

  displaySearch = () => {
    this.setState({ showSearch: true });
  };

  handleSearchStringChange = (event) => {
    this.setState({ searchString: event.target.value });
  };

  handleEnter = (event) => {
    if (event.key === 'Enter') {
      this.props.onSearch(this.state.searchString);
      event.preventDefault();
    }
  };

  render() {
    const {menuItems, classes, title, mainScreen, onSearch} = this.props;
    const {anchorEl, showSearch, searchString} = this.state;
    const open = Boolean(anchorEl);
    const link = mainScreen ? () => this.toggleDrawer(true) : history.goBack;
    const icon = mainScreen ? <MenuIcon/> : <BackIcon/>;
    return (
        <AppBar position="sticky">
          <Toolbar>
            <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" onClick={link}>
              {icon}
            </IconButton>
            {
              !showSearch &&
              <Typography variant="h6" color="inherit" className={classes.grow}>
                {title}
              </Typography>
            }
            {
              showSearch &&
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon/>
                </div>
                <InputBase
                  placeholder="Suche Rezept …"
                  onChange={this.handleSearchStringChange}
                  onKeyPress={this.handleEnter}
                  value={searchString}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                />
              </div>
            }
            <div>
              {
                onSearch && !showSearch &&
                <IconButton
                  aria-owns={open ? 'menu-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={this.displaySearch}
                  color="inherit"
                >
                  <SearchIcon/>
                </IconButton>
              }
              { menuItems!==undefined &&
                <IconButton
                  aria-owns={open ? 'menu-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleMenu}
                  color="inherit"
                >
                  <MoreIcon/>
                </IconButton>
              }
              { menuItems !== undefined &&
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={() => this.handleClose()}
                >
                  {
                    menuItems.map((menuItem) => (
                      <MenuItem onClick={() => this.handleClose(menuItem.key)} key={menuItem.key}>{menuItem.name}</MenuItem>
                    ))}
                </Menu>
              }
            </div>
          </Toolbar>
        </AppBar>
    );
  }
}

MainScreenBar.propTypes = {
  classes: PropTypes.object.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  mainScreen: PropTypes.bool.isRequired,
  menuItems: PropTypes.array,
  onMenuSelected: PropTypes.func,
};

const styles = theme => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 10,
  },
  search: {
    flexGrow: 1,
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 5,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 5,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
});


export default withStyles(styles)(MainScreenBar);
