import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Button} from '@material-ui/core';
import {TextField} from '@material-ui/core';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import _ from 'underscore';
import s from 'underscore.string';


class EditKochbuchDialog extends Component {
  state = {
    kochbuch: {
      name: undefined,
      fileName: undefined,
      file: undefined,
    },
    nameInvalid: false, fileInvalid: false,
    editMode: false,
  };


  constructor(props) {
    super(props);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onChange = this.onChange.bind(this);
  }


  /**
   * Properties aktualisieren.
   */
  componentDidUpdate(prevProps) {
    // Wenn sich die Properties geändert haben, das Kochbuch initialisieren
    if (prevProps.kochbuch!==this.props.kochbuch) {
      var kochbuch = _.clone(this.props.kochbuch);
      kochbuch.fileName = undefined;
      kochbuch.file = undefined;
      var editMode = kochbuch.kochbuchId!==undefined;
      this.setState({kochbuch: kochbuch, editMode: editMode});
    }
  }


  /**
   * "Speichern"-Button gedrückt.
   */
  onSave() {
    let {kochbuch, editMode} = this.state;

    // Name und Beschreibung validieren
    let nameInvalid = s.isBlank(kochbuch.name);
    let fileInvalid = _.isUndefined(kochbuch.file) && !editMode;

    // Save-Methode des Parents aufrufen
    if (!nameInvalid && !fileInvalid) {
      this.props.onClose(this.state.kochbuch);
    } else {
      this.setState({nameInvalid: nameInvalid, fileInvalid: fileInvalid});
    }
  }


  /**
   * "Abbruch"-Button gedrückt.
   */
  onCancel() {
    this.props.onClose();
  }


  /**
   * Change-Events behandeln.
   */
  onChange(event) {
    var {kochbuch} = this.state;
    if (event.target.id==='name') {
      kochbuch.name = event.target.value;
    } else if (event.target.id==='file') {
      kochbuch.fileName = event.target.value;
      kochbuch.file = event.target.files[0];
    }
    this.setState({kochbuch: kochbuch});
  }


  /**
   * render()-Funktion
   */
  render() {
    const {open} = this.props;
    const {kochbuch} = this.state;
    const {editMode, nameInvalid, fileInvalid} = this.state;

    return <Dialog
      open={open}
      fullScreen
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{'Kochbuch ' + (editMode ? 'bearbeiten' : 'anlegen')}</DialogTitle>
      <DialogContent>
        <TextField
          id="name" label="Name"
          value={kochbuch.name}
          onChange={this.onChange}
          type="text"
          error={nameInvalid}
          helperText={nameInvalid ? "Bitte einen Kochbuchnamen eingeben." : undefined}
          autoFocus fullWidth required margin="normal"
        />
        <TextField
          id="file" label="Beschreibung"
          value={kochbuch.fileName}
          onChange={this.onChange}
          type="file"
          error={fileInvalid}
          helperText={fileInvalid ? "Bitte eine Datei eingeben." : undefined}
          fullWidth margin="normal"
          required={!editMode}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={this.onCancel} color="primary">
          Abbrechen
        </Button>
        <Button onClick={this.onSave} color="primary">
          {editMode ? 'Speichern' : 'Anlegen'}
        </Button>
      </DialogActions>
    </Dialog>;
  }
}

EditKochbuchDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  kochbuch: PropTypes.object.isRequired,
};

export default EditKochbuchDialog;

