import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Divider, List, ListItem, ListItemText} from "@material-ui/core";
import grey from '@material-ui/core/colors/grey';
import green from '@material-ui/core/colors/green';

import MainScreenBar from '../navigation/MainScreenBar'
import Erinnerungen from '../rest/Erinnerungen';
import history from "../navigation/History";


const styles = theme => ({
  root: {
    marginTop: 60,
    backgroundColor: theme.palette.background.paper,
  },
  primaryActive: {
    color: green[600],
    fontWeight: 'bold',
  },
  primaryInactive: {
    color: grey[500],
  },
  secondaryActive: {
    color: green[500],
  },
  secondaryInactive: {
    color: grey[400],
  },
});


class ErinnerungenScreen extends Component {
  state = {erinnerungen: []};

  constructor(props) {
    super(props);

    // Callbacks registrieren
    this.loadErinnerungen = this.loadErinnerungen.bind(this);
  }


  componentDidMount() {
    this.loadErinnerungen();
  }


  async loadErinnerungen() {
    let erinnerungen = await Erinnerungen.read();
    this.setState({erinnerungen: erinnerungen});
  }


  static showRezept(erinnerung) {
    history.push('/rezept/'+erinnerung.rezeptId);
  }


  render() {
    const {classes, toggleDrawer} = this.props;

    return (
      <div>
        <MainScreenBar
          toggleDrawer={toggleDrawer}
          title={'Erinnerungen'}
          mainScreen={true}
        />
        <div className={classes.root}>
          <List> {
            this.state.erinnerungen.map((erinnerung) => (
                <div key={erinnerung.rezeptId}>
                <ListItem button key={erinnerung.rezeptId} onClick={() => ErinnerungenScreen.showRezept(erinnerung)}>
                  <ListItemText
                    primary={erinnerung.name}
                    secondary={new Date(erinnerung.termin).toLocaleDateString('de-DE', {year: 'numeric', month: 'long', day: 'numeric'})}
                    classes={
                      erinnerung.aktiv ?
                      {primary: classes.primaryActive, secondary: classes.secondaryActive} :
                      {primary: classes.primaryInactive, secondary: classes.secondaryInactive}
                    }
                  />
                </ListItem>
                <Divider/>
              </div>
            ))}
          </List>
        </div>
      </div>
    );
  }
}

ErinnerungenScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ErinnerungenScreen);
