import Rest from "./Rest";

export default class Kategorien extends Rest {
  static read = function() {
    let restUrl = Rest.config.server + '/api/erinnerung';
    return fetch(restUrl, Rest.createHeader()).then(function(response) {
      return response.json();
    });
  };


  static create = function(rezeptId, intervall) {
    let restUrl = Rest.config.server + '/api/erinnerung';
    let formData = new FormData();
    formData.append('rezeptId', rezeptId);
    formData.append('intervall', intervall);
    return fetch(restUrl, Rest.createHeader('put', formData)).then(function(response) {
      return response.json();
    });
  };


  static update = function(erinnerung) {
    let restUrl = Rest.config.server + '/api/erinnerung/' + erinnerung.rezeptId;
    let formData = new FormData();
    formData.append('rezeptId', erinnerung.rezeptId);
    formData.append('intervall', erinnerung.intervall);
    return fetch(restUrl, Rest.createHeader('post', formData)).then(function(response) {
      return response.json();
    });
  };


  static delete = function(rezeptId) {
    let restUrl = Rest.config.server + '/api/erinnerung/' + rezeptId;
    return fetch(restUrl, Rest.createHeader('delete')).then(function(response) {
      return response.json();
    });
  };
}
