import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Button, Container, Grid, Typography} from "@material-ui/core";
import * as PropTypes from "prop-types";


const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3, 0, 3),
  },
  heroButtons: {
    marginTop: theme.spacing(3),
  },
}));


export default function HeroContent(props) {
  const classes = useStyles();
  const {title, subtitle, primaryAction, secondaryAction} = props;
  
  return <div className={classes.heroContent}>
    <Container maxWidth="sm">
      <Typography component="h2" variant="h3" align="center" color="textPrimary" gutterBottom>
        {title}
      </Typography>
      {subtitle &&
        <Typography variant="body1" align="center" color="textSecondary" paragraph>
          {subtitle}
        </Typography>
      }
  
      {(primaryAction || secondaryAction) &&
        <div className={classes.heroButtons}>
          <Grid container spacing={2} justify="center">
            {primaryAction &&
              <Grid item>
                <Button variant="contained" color="primary">
                  Main call to action
                </Button>
              </Grid>
            }
            {secondaryAction &&
              <Grid item>
                <Button variant="outlined" color="primary">
                  Secondary action
                </Button>
              </Grid>
            }
          </Grid>
        </div>
      }
    </Container>
  </div>;
}

HeroContent.propTypes = {
  title: PropTypes.any,
  subtitle: PropTypes.any,
  primaryAction: PropTypes.any,
  secondaryAction: PropTypes.any
};
