import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Button, Divider, List, ListItem, ListItemText} from '@material-ui/core';
import {Radio} from '@material-ui/core';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';

const styles = {};


class ChangeKategorieDialog extends Component {
  state = {selectedKategorie:''};

  constructor(props) {
    super(props);
    this.state.selectedKategorie = this.props.selectedKategorie;

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedKategorie !== prevProps.selectedKategorie) {
      this.setState( {selectedKategorie: this.props.selectedKategorie});
    }
  }


  handleChange(event) {
    var kategorieId = event.target!==undefined ? event.target.value : event;
    this.setState({selectedKategorie: kategorieId});
  }


  render() {
    const {open, onClose, kategorien} = this.props;
    const {selectedKategorie} = this.state;

    return <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      scroll={'paper'}
      fullScreen
      fullWidth={true}
      maxWidth={'md'}
    >
      <DialogTitle id="form-dialog-title">{'Bitte die neue Kategorie auswählen:'}</DialogTitle>
      <DialogContent>
        <List> {
          kategorien.map((kategorie) => (
            <div key={kategorie.kategorieId}>
              <ListItem button key={kategorie.kategorieId} onClick={() => this.handleChange(kategorie.kategorieId)}>
                <Radio
                  checked={kategorie.kategorieId===selectedKategorie}
                  onChange={this.handleChange}
                  value={kategorie.kategorieId}
                  disableRipple={true}
                />
                <ListItemText primary={kategorie.name}/>
              </ListItem>
              <Divider/>
            </div>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="primary">Abbrechen</Button>
        <Button onClick={() => onClose(selectedKategorie)} color="primary">Speichern</Button>
        <Button onClick={() => onClose(-1)} color="primary">Löschen</Button>
      </DialogActions>
    </Dialog>;
  }
}

ChangeKategorieDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  kategorien: PropTypes.array.isRequired,
  selectedKategorie: PropTypes.string,
};

export default withStyles(styles)(ChangeKategorieDialog);

