import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import history from './History';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import KategorieIcon from '@material-ui/icons/LabelOutlined';
import RezeptIcon from '@material-ui/icons/LocalDining';
import ErinnerungIcon from '@material-ui/icons/Event';
import KochbuchIcon from '@material-ui/icons/FolderOpen';
import EinstellungenIcon from '@material-ui/icons/Settings';
import LogoutIcon from '@material-ui/icons/ExitToApp';


class Sidebar extends Component {
  state = {open: false};

  constructor(props) {
    super(props);
    this.state.open = props.open;
    this.toggleDrawer = props.toggleDrawer;
    this.handleClose = this.handleClose.bind(this);
    // this.toggleDrawer = this.toggleDrawer.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({open: nextProps.open});
  }

  // toggleDrawer(open) {
  //   this.setState({open: open});
  // };

  handleClose(link) {
    this.toggleDrawer(false);
    history.push(link);
  };

  render() {
    const {classes} = this.props;
    const rezeptEntries = [
      {name: 'Kategorien', icon: <KategorieIcon/>, link: '/kategorien'},
      {name: 'Rezepte', icon: <RezeptIcon/>, link: '/rezepte'},
      {name: 'Erinnerungen', icon: <ErinnerungIcon/>, link: '/erinnerungen'},
      {name: 'Kochbücher', icon: <KochbuchIcon/>, link: '/kochbuecher'},
    ];
    const configEntries = [
      {name: 'Einstellungen', icon: <EinstellungenIcon />, link: '/einstellungen'},
      {name: 'Abmelden', icon: <LogoutIcon />, link: '/logout'},
    ];
    const sideList = (
      <div className={classes.list}>
        <List> {
          rezeptEntries.map((entry) => (
            <ListItem button key={entry.link} onClick={() => this.handleClose(entry.link)}>
              <ListItemIcon>{entry.icon}</ListItemIcon>
              <ListItemText primary={entry.name} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List> {
          configEntries.map( (entry) => (
            <ListItem button key={entry.link} onClick={() => this.handleClose(entry.link)}>
              <ListItemIcon>{entry.icon}</ListItemIcon>
              <ListItemText primary={entry.name} />
            </ListItem>
          ))}
        </List>
      </div>
    );

    return (
      <div>
        <SwipeableDrawer
          open={this.state.open}
          onClose={() => this.toggleDrawer(false)}
          onOpen={() => this.toggleDrawer(true)}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={() => this.toggleDrawer(false)}
            onKeyDown={() => this.toggleDrawer(false)}
          >
            {sideList}
          </div>
        </SwipeableDrawer>
      </div>
    );
  }
}


Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
};


const styles = {
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
};

export default withStyles(styles)(Sidebar);
