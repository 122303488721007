import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button} from '@material-ui/core';
import {TextField} from '@material-ui/core';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';


export default function EditRezeptDialog(props) {
  const {open, onClose} = props;
  const {rezept, kategorien, kochbuecher} = props;
  
  const [editRezeptId, setEditRezeptId] = useState();
  const [editName, setEditName] = useState();
  const [editKategorieId, setEditKategorieId] = useState();
  const [editKochbuchId, setEditKochbuchId] = useState();
  const [editFile, setEditFile] = useState();
  const [editFileName, setEditFileName] = useState();
  const [editSeite, setEditSeite] = useState(1);
  const [editThumbnail, setEditThumbnail] = useState();
  const [editThumbnailName, setEditThumbnailName] = useState();

  const [nameInvalid, setNameInvalid] = useState(false);
  const [fileInvalid, setFileInvalid] = useState(false);
  const [editKochbuch, setEditKochbuch] = useState(false);
  const editMode = rezept!==undefined;
  
  useEffect(() => {
    console.log(rezept);
    setEditRezeptId(rezept ? rezept.rezeptId : undefined);
    setEditName(rezept ? rezept.name : undefined);
    setEditFile(rezept ? rezept.file : undefined);
    setEditFileName(rezept ? rezept.fileName : undefined);
    setEditSeite(rezept ? rezept.seite : 1);
    setEditThumbnail(rezept ? rezept.thumbnail : undefined);
    setEditThumbnailName(rezept ? rezept.thumbnailName : undefined);
    setEditKategorieId(rezept ? rezept.kategorieId : undefined);
    setEditKochbuchId(rezept ? rezept.kochbuchId : undefined);
  }, [rezept]);
  
  /**
   * "Speichern"-Button gedrückt.
   */
  function onSave() {
    onClose({
      rezeptId: editRezeptId,
      name: editName,
      seite: editSeite,
      kategorieId: editKategorieId,
      kochbuchId: editKochbuchId,
      file: editFile,
      fileName: editFileName,
      thumbnail: editThumbnail,
      thumbnailName: editThumbnailName,
    });

    // // Name und Beschreibung validieren
    // let nameInvalid = s.isBlank(rezept.name);
    // let fileInvalid = _.isUndefined(rezept.file) && !editKochbuch && !editMode;
    //
    // // Save-Methode des Parents aufrufen
    // if (!nameInvalid && !fileInvalid) {
    //   rezept.kategorieId = s.isBlank(rezept.kategorieId) ? undefined : rezept.kategorieId;
    //   rezept.kochbuchId = s.isBlank(rezept.kochbuchId) ? undefined : rezept.kochbuchId;
    //   this.props.onClose(this.state.rezept);
    // } else {
    //   this.setState({nameInvalid: nameInvalid, fileInvalid: fileInvalid});
    // }
  }
  
  /**
   * "Abbruch"-Button gedrückt.
   */
  function onCancel() {
    onClose();
  }

  return <Dialog
    open={open}
    fullScreen
    aria-labelledby="form-dialog-title"
  >
    <DialogTitle id="form-dialog-title">{'Rezept ' + (editMode ? 'bearbeiten' : 'anlegen')}</DialogTitle>
    <DialogContent>
      <TextField
        id="name" label="Name"
        value={editName}
        onChange={e => setEditName(e.target.value)}
        type="text"
        error={nameInvalid}
        helperText={nameInvalid ? "Bitte einen Rezeptnamen eingeben." : undefined}
        autoFocus fullWidth required margin="normal"
      />
      <TextField
        id="kategorie" label="Kategorie"
        value={editKategorieId}
        onChange={e => setEditKategorieId(e.target.value)}
        type="text" select
        fullWidth margin="normal"
      >
        {kategorien.map(kategorie => (
          <option name={kategorie.kategorieId} key={kategorie.kategorieId} value={kategorie.kategorieId}>
            {kategorie.name}
          </option>
        ))}
      </TextField>
      
      {!editKochbuch &&
      <TextField
        id="file" label="Beschreibung"
        value={editFileName}
        onChange={e => {
          setEditFileName(e.target.value);
          setEditFile(e.target.files[0]);
        }}
        type="file"
        error={fileInvalid}
        helperText={fileInvalid ? "Bitte eine Bechreibung eingeben." : undefined}
        fullWidth margin="normal"
        required={!editMode}
        InputLabelProps={{ shrink: true }}
      />
      }
      {editKochbuch &&
      <TextField
        id="kochbuch" label="Kochbuch"
        value={editKochbuchId}
        onChange={e => setEditKochbuchId(e.target.value)}
        type="text" select disabled={true}
        margin="normal" fullWidth
      >
        {kochbuecher.map(kochbuch => (
          <option key={kochbuch.kochbuchId} value={kochbuch.kochbuchId}>
            {kochbuch.name}
          </option>
        ))}
      </TextField>
      }
      <TextField
        id="seite" label="Seite"
        value={editSeite}
        onChange={e => setEditSeite(e.target.value)}
        type="number"
        autoFocus margin="normal" fullWidth required
      />
      <TextField
        id="thumbnail" label="Thumbnail"
        value={editThumbnailName}
        onChange={e => {
          setEditThumbnailName(e.target.value);
          setEditThumbnail(e.target.files[0]);
        }}
        type="file"
        fullWidth
        margin="normal"
        InputLabelProps={{ shrink: true }}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} color="primary">
        Abbrechen
      </Button>
      <Button onClick={onSave} color="primary">
        {editMode ? 'Speichern' : 'Anlegen'}
      </Button>
    </DialogActions>
  </Dialog>;
}


EditRezeptDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  rezept: PropTypes.object.isRequired,
  kategorien: PropTypes.array.isRequired,
  kochbuecher: PropTypes.array.isRequired,
};

