import React from 'react';
import PropTypes from 'prop-types';
import {Button} from '@material-ui/core';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';


function DeleteKategorieDialog(props){
  const {open, onClose, kategorie} = props;

  return <Dialog
    open={open}
    aria-labelledby="form-dialog-title"
  >
    <DialogTitle id="form-dialog-title">{'Kategorie löschen'}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Soll die Kategorie <b>{kategorie ? kategorie.name : ''}</b> wirklich gelöscht werden?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => onClose(false)} color="primary">
        Nein
      </Button>
      <Button onClick={() => onClose(true)} color="primary">
        Ja
      </Button>
    </DialogActions>
  </Dialog>;
}

DeleteKategorieDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  kategorie: PropTypes.object,
};

export default DeleteKategorieDialog;
