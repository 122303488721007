import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Container, Grid} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  cardGrid: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));


export default function GridContainer(props) {
  const classes = useStyles();
  const {children} = props;
  
  return <Container className={classes.cardGrid} maxWidth="md">
    <Grid container spacing={4}>
      {children}
    </Grid>
  </Container>;
}
