import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Button} from '@material-ui/core';
import {TextField} from '@material-ui/core';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';

const styles = {};


class CreateErinnerungDialog extends Component {
  state = {intervall: 4};


  constructor(props) {
    super(props);

    if (this.props.erinnerung!==undefined) {
      this.state.intervall = this.props.erinnerung.intervall;
    }
  }


  componentDidUpdate(prevProps) {
    if (prevProps.erinnerung!==this.props.erinnerung) {
      this.setState({intervall: this.props.erinnerung!==undefined ? this.props.erinnerung.intervall : 4});
    }
  }


  render() {
    const {open, onClose} = this.props;
    const {intervall} = this.state;

    return <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{'Erinnerung setzen'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Bitte die Anzahl der Wochen eingeben, nach denen erinnert werden soll.
        </DialogContentText>
        <TextField
          autoFocus
          id="intervall"
          label="Wochen"
          type="number"
          min={0}
          fullWidth
          value={intervall}
          onChange={(e) => this.setState({intervall: e.target.value})}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="primary">Abbrechen</Button>
        <Button onClick={() => onClose(intervall)} color="primary">Anlegen</Button>
        {this.props.erinnerung !== undefined &&
          <Button onClick={() => onClose(-1)} color="primary">Löschen</Button>
        }
      </DialogActions>
    </Dialog>;
  }
}

CreateErinnerungDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(CreateErinnerungDialog);

