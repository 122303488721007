import React from "react";
import * as PropTypes from "prop-types";
import {Button, IconButton} from "@material-ui/core";
import {Card, CardActions, CardContent, CardMedia} from "@material-ui/core";
import {Grid, Link, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';


const useStyles = makeStyles((/*theme*/) => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
}));


export default function GridEntry(props) {
  const classes = useStyles();
  const {title, subtitle, thumbnail, viewLink, editLink, deleteLink, sizeX, sizeL} = props;
  
//  return <Grid item xs={6} sm={6} md={4} lg={3}>
  return <Grid item xs={sizeX} sm={6} md={sizeL} lg={sizeL}>
    <Card className={classes.card}>
      {thumbnail &&
        <CardMedia
          className={classes.cardMedia}
          component={Link}
          image={thumbnail}
          title={title}
          href={viewLink}
        />
      }
      <CardContent className={classes.cardContent}>
        <Typography gutterBottom variant="body1">
          {title}
        </Typography>
        {subtitle &&
          <Typography variant="body2">
            {subtitle}
          </Typography>
        }
      </CardContent>
      <CardActions>
        <Button component={Link} size="small" color="primary" href={viewLink}>
          Anzeigen
        </Button>
        <IconButton size="small" aria-label="edit" onClick={editLink}>
          <EditIcon />
        </IconButton>
        <IconButton size="small" aria-label="delete" onClick={deleteLink}>
          <DeleteIcon />
        </IconButton>
      </CardActions>
    </Card>
  </Grid>;
}

GridEntry.propTypes = {
  title: PropTypes.any,
  subtitle: PropTypes.any,
  thumbnail: PropTypes.any,
  viewLink: PropTypes.any,
  editLink: PropTypes.any,
  deleteLink: PropTypes.any,
};
