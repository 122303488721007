export default class Rest {
  static getAuthStr() {
    let auth = localStorage.getItem('auth');
    return `Basic ${auth}`;
  }
  
  
  static init(location) {
    let server = `${location.protocol}//${location.hostname}${location.port==='3000' ? ':8080' : ''}`;
    sessionStorage.setItem('server', server);
  }
  
  
  static get config() {
    return {
      server: sessionStorage.getItem('server'),
      user: localStorage.getItem('user'),
      auth: localStorage.getItem('auth'),
    };
  }

  
  static createHeader(method = 'get', body) {
    return {
      method: method,
      body: body,
      credentials: 'same-origin',
      headers: {
        'Authorization': Rest.getAuthStr(),
      },
    };
  }
  
  
  static login = function() {
    let restUrl = `${Rest.config.server}/api/file/thumb_default?rid=${localStorage.getItem('auth')}`;
    return fetch(restUrl).then(function(response) {
      return response.status===200;
    });
  };
}
