import Rest from "./Rest";

export default class Kochbuecher extends Rest {
  static read = function() {
    let restUrl = Rest.config.server + '/api/kochbuch';
    return fetch(restUrl, Rest.createHeader()).then(function(response) {
      return response.json();
    });
  };


  static create = function(kochbuch) {
    let restUrl = Rest.config.server + '/api/kochbuch';

    let formData = new FormData();
    formData.append('name', kochbuch.name);
    formData.append("file", kochbuch.file);
    return fetch(restUrl, Rest.createHeader('put', formData)).then(function(response) {
      return response.json();
    });
  };


  static update = function(kochbuch) {
    let restUrl = Rest.config.server + '/api/kochbuch/' + kochbuch.kochbuchId;

    let formData = new FormData();
    formData.append('name', kochbuch.name);
    if (kochbuch.file!==undefined) {
      formData.append("file", kochbuch.file);
    }
    return fetch(restUrl, Rest.createHeader('post', formData)).then(function(response) {
      return response.json();
    });
  };


  static delete = function(kochbuch) {
    let restUrl = Rest.config.server + '/api/kochbuch/' + kochbuch.kochbuchId;
    return fetch(restUrl, Rest.createHeader('delete')).then(function(response) {
      return response.json();
    });
  };

}
