import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Button} from '@material-ui/core';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';

const styles = {};


function DeleteKochbuchDialog(props){
  const {open, onClose, kochbuch} = props;

  return <Dialog
    open={open}
    aria-labelledby="form-dialog-title"
  >
    <DialogTitle id="form-dialog-title">{'Kochbuch löschen'}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Soll das Kochbuch <b>{kochbuch.name}</b> wirklich gelöscht werden?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => onClose(false)} color="primary">
        Nein
      </Button>
      <Button onClick={() => onClose(true)} color="primary">
        Ja
      </Button>
    </DialogActions>
  </Dialog>;
}

DeleteKochbuchDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  kochbuch: PropTypes.object.isRequired,
};

export default withStyles(styles)(DeleteKochbuchDialog);
