import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {MobilePDFReader} from 'react-read-pdf';

import MainScreenBar from '../navigation/MainScreenBar'
import Rezepte from '../rest/Rezepte';
import Kategorien from '../rest/Kategorien';
import Erinnerungen from '../rest/Erinnerungen';
import ChangeKategorieDialog from "../rezepte/ChangeKategorieDialog";
import CreateErinnerungDialog from "../rezepte/CreateErinnerungDialog";
import Rest from "../rest/Rest";


const styles = theme => ({
  root: {
    marginTop: 0,
    backgroundColor: theme.palette.background.paper,
    overflow: 'scroll',
    height: '100%',
  },
});


class RezeptScreen extends Component {
  editRezept = {};

  state = {
    rezept: {name: ''}, kategorien: [], erinnerung: undefined,
    showChangeKategorieDialog : false,
    showCreateErinnerungDialog : false,
  };
  config = {
    server: localStorage.getItem('server'),
  };


  constructor(props) {
    super(props);

    // Callbacks registrieren
    this.loadRezept = this.loadRezept.bind(this);
    this.handleMenu = this.handleMenu.bind(this);
    this.handleChangeKategorie = this.handleChangeKategorie.bind(this);
    this.handleCreateErinnerung = this.handleCreateErinnerung.bind(this);
    this.showCreateErinnerungDialog = this.showCreateErinnerungDialog.bind(this);
  }


  componentDidMount() {
    this.loadRezept();
  }


  async loadRezept() {
    const {rezeptId} = this.props.match.params;
    let rezept = await Rezepte.read(rezeptId);
    let kategorien = await Kategorien.read();
    this.setState({rezept: rezept, kategorien: kategorien});
  }


  handleMenu(key) {
    if (key==='UK') {
      this.setState({showChangeKategorieDialog: true});
    } else if (key==='CE') {
      this.showCreateErinnerungDialog();
    }
  }


  async showCreateErinnerungDialog() {
    // Erinnerung suchen
    const {rezept} = this.state;
    let erinnerungen = await Erinnerungen.read();

    let erinnerung = undefined;
    erinnerungen.forEach(function(data) {
      if (data.rezeptId===rezept.rezeptId) {
        erinnerung = data;
      }
    });

    this.setState({showCreateErinnerungDialog: true, erinnerung: erinnerung});
  }


  async handleChangeKategorie(kategorieId) {
    let {rezept} = this.state;

    if (kategorieId===-1) {
      if (rezept.kategorieId!==null) {
        Rezepte.removeFromKategorie(rezept.rezeptId, rezept.kategorieId);
        this.loadRezept();
      }
    } else if (kategorieId!==undefined) {
      if (kategorieId!==rezept.kategorieId) {
        Rezepte.addToKategorie(rezept.rezeptId, kategorieId);
        this.loadRezept();
      }
    }
    this.setState({showChangeKategorieDialog: false});
  }


  async handleCreateErinnerung(intervall) {
    const {rezept, erinnerung} = this.state;

    if (intervall===undefined) {
    } else if (intervall===-1) {
      Erinnerungen.delete(rezept.rezeptId);
      this.loadRezept();
    } else {
      if (erinnerung!==undefined) {
        Erinnerungen.update({rezeptId: rezept.rezeptId, intervall: intervall});
      } else {
        Erinnerungen.create(rezept.rezeptId, intervall);
      }
      this.loadRezept();
    }
    this.setState({showCreateErinnerungDialog: false});
  }


  render() {
    const {rezept, kategorien, erinnerung} = this.state;
    const {showChangeKategorieDialog, showCreateErinnerungDialog} = this.state;
    const {toggleDrawer} = this.props;

    var menuItems = [
      {name: 'Erinnerung', key: 'CE'},
      {name: 'Kategorie', key: 'UK'},
    ];

    var uri = {
      url:`${Rest.config.server}/api/file/${rezept.fileId}?rid=${localStorage.getItem('auth')}`,
  
      // url:Rezepte.config.server+'/api/file/'+rezept.fileId,
      httpHeaders:{Authorization:Rezepte.getAuthStr()},
    };
    uri.indexOf = function() {return -1};
    uri.lastIndexOf = function() {return -1};
    uri.substring = function() {return rezept.name};

    return (
      <div>
        <MainScreenBar
          toggleDrawer={toggleDrawer}
          title={this.state.rezept.name}
          mainScreen={false}
          menuItems={menuItems}
          onMenuSelected={this.handleMenu}
        />
        {rezept.fileId !== undefined &&
          <MobilePDFReader url={uri} page={rezept.seite}/>
        }

        <ChangeKategorieDialog open={showChangeKategorieDialog} kategorien={kategorien} selectedKategorie={rezept.kategorieId} onClose={this.handleChangeKategorie}/>
        <CreateErinnerungDialog open={showCreateErinnerungDialog} erinnerung={erinnerung}  onClose={this.handleCreateErinnerung} />
      </div>
    );
  }
}

RezeptScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RezeptScreen);
