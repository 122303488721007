import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {CssBaseline} from '@material-ui/core';
import {Fab} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';

import MainScreenBar from '../navigation/MainScreenBar'
import Kategorien from '../rest/Kategorien';
import EditKategorieDialog from './EditKategorieDialog';
import DeleteKategorieDialog from '../kategorien/DeleteKategorieDialog';

import Footer from '../navigation/Footer';
import HeroContent from '../navigation/HeroContent'
import GridContainer from '../navigation/GridContainer'
import GridEntry from '../navigation/GridEntry'


const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));


export default function KategorienScreen(props) {
  const classes = useStyles();
  const {toggleDrawer} = props;
  
  const [kategorien, setKategorien] = useState([]);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedKategorie, setSelectedKategorie] = useState(undefined);

  const menuItems = [
    {name: 'Neue Kategorie', key: 'C'},
  ];
  
  
  useEffect(() => {
    loadKategorien();
  }, []);


  async function loadKategorien() {
    try {
      let kategorien = await Kategorien.read();
      setKategorien(kategorien);
    } catch (error) {
      console.log(error);
    }
  }


  function showEditDialog(kategorie) {
    setSelectedKategorie(kategorie)
    setShowCreateDialog(true);
  }

  async function handleCreate(kategorie) {
    setShowCreateDialog(false);
    if (kategorie) {
      if (selectedKategorie) {
        await Kategorien.update(kategorie);
      } else {
        await Kategorien.create(kategorie);
      }
    }
  }

  async function handleDelete(confirmDelete) {
    setShowDeleteDialog(false);
    if (confirmDelete) {
      await Kategorien.delete(selectedKategorie.kategorieId);
      loadKategorien();
    }
  }
  
  return (
    <React.Fragment>
      <CssBaseline />
      <MainScreenBar
        toggleDrawer={toggleDrawer}
        title={'Kategorien'}
        mainScreen={true}
        menuItems={menuItems}
        onMenuSelected={showEditDialog}
      />
      <main>
        {/* Hero unit */}
        <HeroContent
          title={'Kategorien'}
        />
  
        {/* Categories */}
        <GridContainer>
          {kategorien.map((kategorie) => (
            <GridEntry
              key={kategorie.kategorieId}
              title={kategorie.name}
              thumbnail={Kategorien.getThumbnail(kategorie.kategorieId)}
              viewLink={'/#/kategorie/'+kategorie.kategorieId + '/' + kategorie.name}
              editLink={() => showEditDialog(kategorie)}
              deleteLink={() => {
                setSelectedKategorie(kategorie);
                setShowDeleteDialog(true);
              }}
              sizeX={6}
              sizeL={3}
            />
          ))}
        </GridContainer>
      </main>
  
      {/* Footer */}
      <Footer />

      <Fab color="primary" aria-label="Add" className={classes.fab} onClick={() => showEditDialog()}>
        <AddIcon/>
      </Fab>

      <EditKategorieDialog open={showCreateDialog} kategorie={selectedKategorie} onClose={handleCreate}/>
      <DeleteKategorieDialog open={showDeleteDialog} kategorie={selectedKategorie} onClose={handleDelete}/>
    </React.Fragment>
  );
}
