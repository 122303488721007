import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Fab} from '@material-ui/core';
import {Divider, List, ListItem, ListItemText} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';

import MainScreenBar from '../navigation/MainScreenBar'
import Kochbuecher from '../rest/Kochbuecher';
import history from "../navigation/History";
import EditKochbuchDialog from "./EditKochbuchDialog";
import _ from "underscore";


const styles = theme => ({
  root: {
    marginTop: 60,
    backgroundColor: theme.palette.background.paper,
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
  },
});


class KochbuecherScreen extends Component {
  state = {kochbuecher: [], showAddKochbuchDialog: false};
  editKochbuch = {};
  menuItems = [
    {name: 'Neues Kochbuch', key: 'C'},
  ];


  constructor(props) {
    super(props);

    // Callbacks registrieren
    this.loadKochbuecher = this.loadKochbuecher.bind(this);
    this.handleMenu = this.handleMenu.bind(this);
    this.showAddKochbuchDialog = this.showAddKochbuchDialog.bind(this);
    this.handleCreateKochbuch = this.handleCreateKochbuch.bind(this);
  }


  componentDidMount() {
    this.loadKochbuecher();
  }


  handleMenu(key) {
    if (key==='C') {
      this.setState({showAddKochbuchDialog: true});
    }
  }


  async loadKochbuecher() {
    let kochbuecher = await Kochbuecher.read();
    this.setState({kochbuecher: kochbuecher});
  }


  static showRezepte(kochbuch) {
    history.push('/kochbuch/'+kochbuch.kochbuchId+'/'+kochbuch.name);
  }


  showAddKochbuchDialog() {
    this.editKochbuch = {
      name: undefined,
      fileId: undefined,
    };
    this.setState({showAddKochbuchDialog: true});
  }


  async handleCreateKochbuch(kochbuch) {
    this.setState({showAddKochbuchDialog: false});
    if (!_.isUndefined(kochbuch)) {
      await Kochbuecher.create(kochbuch);
      this.loadKochbuecher();
    }
  }


  render() {
    const {classes, toggleDrawer} = this.props;
    const {showAddKochbuchDialog} = this.state;

    return (
      <div>
        <MainScreenBar
          toggleDrawer={toggleDrawer}
          title={'Kochbücher'}
          mainScreen={true}
          menuItems={this.menuItems}
          onMenuSelected={this.handleMenu}
        />
        <div className={classes.root}>
          <List> {
            this.state.kochbuecher.map( (kochbuch) => (
              <div key={kochbuch.kochbuchId}>
                <ListItem button key={kochbuch.kochbuchId} onClick={() => KochbuecherScreen.showRezepte(kochbuch)}>
                  <ListItemText primary={kochbuch.name} />
                </ListItem>
                <Divider/>
              </div>
            ))}
          </List>
        </div>

        <Fab color="primary" aria-label="Add" className={classes.fab} onClick={this.showAddKochbuchDialog}>
          <AddIcon/>
        </Fab>

        <EditKochbuchDialog open={showAddKochbuchDialog} kochbuch={this.editKochbuch} onClose={this.handleCreateKochbuch}/>

      </div>
    );
  }
}

KochbuecherScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(KochbuecherScreen);
